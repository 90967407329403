.button {
  color: inherit;
  padding: 15px 20px;
  transition: all .3s ease-in-out;
  border-radius: 8px;
  text-align: center;
  font-size: 1.3125rem;
  font-family: 'SourceSansPro';
  font-weight: 600;

  &--main {
    border: 1px solid $color-main;
    color: $color-main;

    &:hover {
      background-color: $color-main;
      border-color: $color-main;
      color: white;
    }
  }

  &--reverse {
    color: white;
    background-color: $color-main;
    border: 1px solid white;

    &:hover {
      background-color: white;
      color: $color-main;
      border-color: $color-main;
    }
  }
}