.subsite {
  margin-bottom: 50px;
  //padding-top: 80px;
  &__header {
    border-bottom: 1px solid $color-main;
    width: 100%;
    height: 20vw;
    display: flex;
    align-items: flex-end;
    margin-bottom: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom -5vw center;

    @include mq($until: lg) {
      height: 28vw;
    }

    @include mq($until: xs) {
      height: 40vw;
    }

    div {
      background-color: rgba(255,255,255, .8);
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex;
      align-content: center;

      h1 {
        margin: 0;
      }
    }
  }
}

.static-icon {
  display: flex;
  align-items: flex-start;

  &__icon {
    margin-right: 10px;
    fill: $color-main;
  }
}