.services-container {
  display: grid;
  grid-template-columns: repeat(6,1fr);
  justify-items: center;
  background-color: white;
  transform: translateY(-50%);
  border-radius: 8px;
  padding: 35px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  position: relative;
  overflow: hidden;

  @include mq($until: xl) {
    transform: translateY(-30%);
    padding: 20px;
  }

  @include mq($until: lg) {
    row-gap: 30px;
    grid-template-columns: repeat(6,1fr);
    transform: translateY(0);
    box-shadow: unset;
    margin: 20px -20px -20px -20px;
    padding-bottom: 40px;
  }

  @include mq($until: md) {
    grid-template-columns: repeat(3,1fr);
  }

  .service {

    &:hover {
      .service__name {
        color: $color-main;
      }

      .service__svg {
        fill: url(#my-cool-gradient) #447799;
      }

    }

    &__name {
      text-align: center;
      transition: color 1s;
    }

    &__img {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;

      @include mq($until: xl) {
        margin-bottom: 15px;
      }
    }

    &__svg {
      fill: #4D4D4D;
      transition: fill .3s;

      @include mq($until: xl) {
        height: 40px;
      }
    }
  }
}

.service__more {
  background-color: rgba($color-main, .5);
  position: absolute;
  right: 0;
  top: 30%;
  bottom: 30%;
  width: 70px;
  border-radius: 50%;
  transform: translateX(50%);
  transition: background-color .3s;

  @include mq($until: xl) {
    top: 25%;
    bottom: 25%;
  }

  @include mq($until: xs) {
    top: 70%;
    bottom: 0;
    right: 42%;
    left: 42%;
    width: auto;
    transform: translate(0,50%);
  }

  &:hover {
    background-color: rgba($color-main, 1);
  }
}

.service__dots {
  @include serviceDots;
  left: 30%;
  top: 50%;
  transform: translate(-50%, -50%);

  @include mq($until: xs) {
    top: 30%;
    left: 50%;
  }

  &:before {
    content: '';
    @include serviceDots;
    top: -200%;

    @include mq($until: xs) {
      top: unset;
      left: 200%;
    }
  }

  &:after {
    content: '';
    @include serviceDots;
    bottom: -200%;

    @include mq($until: xs) {
      bottom: unset;
      right: 200%;
    }
  }
}