*, *::before, *::after {
    box-sizing: border-box;
}
.site-wrapper{
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
}
.padding-container{
    padding: 0 200px;
    @include mq($until: xxl){padding: 0 150px;}
    @include mq($until: xl){padding: 0 5vw;}
    @include mq($until: md) {padding: 0 30px;}
    @include mq($until: xs) {padding: 0 15px;}
}

.margin-container{
    margin: 0 200px;
    @include mq($until: xxl){margin: 0 150px;}
    @include mq($until: xl){margin: 0 5vw;}
    @include mq($until: md) {margin: 0 30px;}
    @include mq($until: xs) {margin: 0 15px;}
}

.padding-container--right {
    padding-right: 200px;
    @include mq($until: xxl){padding-right: 150px;}
    @include mq($until: xl){padding-right: 5vw;}
    @include mq($until: md) {padding-right: 30px;}
    @include mq($until: xs) {padding-right: 15px;}
}

footer{
    margin-top: auto;
}
.no-gutters{
    & > .col-left{
        padding-right: 30px;
        @include mq($until: md) {padding-right: 0;}
    }
    & > .col-right{
        padding-left: 30px;
        @include mq($until: md) {padding-left: 0;}
    }
}
.col-left, .col-right, .col-inside{
    margin-bottom: 30px;
}