.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50px;
    width: 100%;
    background-color: transparent;
    z-index: 9;
    min-height: 80px;

    @include mq($until: md) {
        position: absolute;
        top: 0;
        z-index: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }

    &--blog {
      position: relative;
      background-color: $color-main;
      top: 0;
      margin-bottom: 5vw;
    }

    &__menuList{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    &__menuItem{
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 25px;
        padding: 0;
        &::before{
            display: none;
        }
    }
    &__menuLink{
        font-family: 'SourceSansPro', serif;
        font-weight: 600;
        color: white;
        border-bottom: 1px solid rgba(255, 255, 255, 0);
        transition: border-bottom-color .3s;
        font-size: 1.28rem;
        @include mq($until: md) {
            border-bottom: 1px solid rgba(255, 255, 255, 0);
            transition: all .3s;
        }

        &:active, &--active, &--open{
            color: white;
        }

        &:hover {
            color: white;
            border-bottom: 1px solid rgba(255, 255, 255, 1);
            @include mq($until: md) {
                color: white;
                border-bottom: 1px solid rgba(255, 255, 255, 1);
            }
        }
    }
    &__logo {
        max-width: 70%;
        line-height: 0;
    }
}