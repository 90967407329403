.header__submenu{
    position: absolute;
    display: flex;
    flex-direction: column;
    background: $color-main;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 250px;
    right: 0;
    top: calc(76px - 100%);
    padding: 0;
    height: auto;
    max-height: 900px;
    width: auto;
    opacity: 1;
    z-index: 9;
    transform: scaleY(1);
    transform-origin: top center;
    transition: opacity .6s ease-in-out, transform .3s ease-in-out;
    @include mq($until: xl) {
        top: calc(72px - 100%);
    }
    &--hidden{
        opacity: 0;
        transform: scaleY(0);
    }
    .header__menuItem{
        width: auto;
        margin: 10px;
    }
    .header__menuLink{
        color: #fff;
    }
}