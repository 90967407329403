.blog {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mq($until: md) {
    height: auto;
    margin: 50px 0;
  }

  &__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
  }

  &__header {
    padding: 0;
    margin: 0;
  }

  &__posts-container {
    display: grid;
    grid-gap: 30px;
    row-gap: 60px;
    grid-template-columns: repeat(3, 1fr);

    @include mq($until: md) {
      grid-template-columns: 450px;
      justify-content: center;
    }

    @include mq($until: xs) {
      grid-template-columns: 1fr;
      justify-content: center;
    }
  }
}

.post {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 38px -9px rgba(0,0,0,0.25);
  transition: box-shadow .3s, transform .3s;

  &:hover {
    box-shadow: 0 10px 38px 0 rgba(0,0,0,0.35);
    transform: translateY(-20px);
  }

  &__image {
    width: 100%;
    padding-top: 60%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__text-container {
    margin: 30px 40px;
  }

  &__link {
    color: $color-main;
    font-weight: 600;
    text-decoration: underline;
  }

  &__title {
    color: $color-main;
  }

  &__content-container {
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    grid-column-gap: 50px;
    grid-row-gap: 25px;

    @include mq($until: md) {
      grid-template-columns: 1fr;
    }
  }

  &__img {
    position: sticky;
    top: 0;
    width: 100%;

    @include mq($until: md) {
      grid-row: 3/4;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
  }

  &__date-container {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }

  &__date {
    font-size: 13px;
    font-style: italic;
    font-weight: 600;
  }
}

.test {
  float: left;
  height: 50px;
  display: inline-block;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;

  li::before {
    display: none;
  }

  .active {
    color: $color-main;
    font-weight: 600;
  }
}