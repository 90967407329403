.fb {
  //width: 0;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  display: flex;
  align-items: center;
  transition: width .3s;

  @include mq($until: md) {
    top: 30%;
  }

  &__container {
    width: 340px;
    overflow: hidden;
    background-color: white;
    transition: width .5s;

    &--hidden {
      width: 0;
    }
  }

  &__logo {
    cursor: pointer;
    width: 40px;

    @include mq($until: md) {
      width: 30px;
    }

    @include mq($until: sm) {
      width: 25px;
    }
  }
}