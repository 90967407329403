.header-box {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  width: 36vw;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  font-size: inherit;
  background-origin: content-box;
  background-size: auto;
  color: white;
  justify-content: center;
  font-family: 'SourceSansPro';
  font-weight: 600;

  @include mq($until: lg) {
    width: 100%;
    max-width: unset;
    justify-content: center;
    margin: 0;
    margin-bottom: 4rem;
  }

  &--right {
    margin-left: auto;
    padding-bottom: 95px;

    @include mq($until: lg) {
      padding: 20px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    }

    .header-box__title {
      @include mq($until: lg) {
        color: $color-main;
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;

    @include mq($until: lg) {
      margin-top: -2rem;
    }

    &--section3 {
      display: flex;
      justify-content: flex-start;
    }
  }

  &__title {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 1.8125rem;

    @include mq($until: xl) {
      font-size: 1.6rem;
    }
  }

  &__sub-title {
    margin-top: 1em;
    margin-bottom: 2em;
    padding: 0;
    text-align: right;
    font-weight: 400;

    @include mq($until: xl) {
      margin-bottom: 1em;
    }
  }

  &--secondary {
    max-width: 640px;
    background-color: rgba(255, 255, 255, .85);
    padding: 45px;
    border-radius: 8px;

    @include mq($until: xl) {
      width: 48vw;
    }

    @include mq($until: lg) {
      width: 100%;
      max-width: unset;
      box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
    }

    @include mq($until: xs) {
      width: 100%;
    }
  }

  p {
    font-size: inherit;
  }
}