// PX to REM - Usage: font-size: rem(37px);
@function rem($px, $base: $base-font-size) {
    @return ($px / $base) * 1rem;
}

@mixin serviceDots {
    background-color: white;
    width: 7px;
    border-radius: 50%;
    height: 7px;
    position: absolute;

    @include mq($until: xl) {
        height: 6px;
        width: 6px;
    }
}